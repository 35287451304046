.tictacbody {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color-scheme:  dark;
  color: rgba(3, 3, 3, 0.87);
  background-color: #ffffff;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

.tictacbody {
  margin: 0;
  display: flex;
  justify-content: center;
  min-width: 320px;
  min-height: 100vh;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.board {
  width: fit-content;
  margin: 40px auto;
  text-align: center;
}

.board h1 {
  color: #080808;
  margin-bottom: 16px;
}

.board button {
  padding: 8px 12px;
  margin: 25px;
  background: transparent;
  border: 2px solid #000000;
  color: #000000;
  width: 100px;
  border-radius: 5px;
  transition: 0.2s;
  font-weight: bold;
  cursor: pointer;
}
a {
  text-decoration: none;
  color: #000000;
}

a:hover {
  color: #ffffff;
}

.board button:hover {
  background: #000000;
  color: #ffffff;
}

.board .game {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.turn {
  display: flex;
  justify-content: center;
  margin: 15px auto;
  width: fit-content;
  position: relative;
  border-radius: 10px;
}

.turn .square,
.winner .square {
  width: 70px;
  height: 70px;
  pointer-events: none;
  border-color: transparent;
}

.square.is-selected {
  color: #fff;
  background: #09f;
}

.winner {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.winner .text {
  background: #ffffff;
  height: 300px;
  width: 320px;
  border: 2px solid #eee;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.winner .win {
  margin: 0 auto;
  width: fit-content;
  border: 2px solid #eee;
  border-radius: 10px;
  display: flex;
  gap: 15px;
}

.square {
  width: 100px;
  height: 100px;
  border: 2px solid #050505;
  border-radius: 5px;
  display: grid;
  place-items: center;
  cursor: pointer;
  font-size: 48px;
}