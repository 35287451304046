.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.header {
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
}

.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Overlay semi-transparente para mejor legibilidad */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 250px;
  height: auto;
  margin-bottom: 1rem;
}

.language-buttons {
  height: 5%;
  position: absolute;
  top: 10px;
  right: 10px;
}

.language-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 1mm;
  transition: transform 0.1s ease;
}

.language-button:hover {
  transform: scale(1.1);
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.nav-link {
  color: rgb(252, 251, 251);
  text-decoration: none;
  margin: 0 1rem;
  font-weight: bold;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #a777e3;
}

.language-button {
  background: none;
  border: none;
  margin: 0 0.5rem;
  cursor: pointer;
  font-size: 1.5rem;
  color: white;
  transition: transform 0.3s;
}

.language-button:hover {
  transform: scale(1.1);
}

.title {
  font-size: 3rem;
  margin: 0;
}

.subtitle {
  font-size: 1.5rem;
  margin: 0.5rem 0;
}

.cta-button {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background: white;
  color: #6e8efb;
  border-radius: 0.5rem;
  text-decoration: none;
  font-weight: bold;
  transition: background 0.3s, color 0.3s;
}

.cta-button:hover {
  background: #6e8efb;
  color: white;
}
.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  margin: 0 auto 20px;
}
.section {
  margin: 2rem 0;
  width: 100%;
  max-width: 800px;
}

.section-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.paragraph {
  font-size: 1.125rem;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.project-item {
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  margin: 1rem;
  padding: 1rem;
  width: calc(33% - 2rem);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.project-item:hover {
  transform: scale(1.05);
}

.project-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.project-image {
  width: 100%;
  max-width: 300px;
  height: auto;
  margin-bottom: 10px;
}

.project-description {
  font-size: 1rem;
  margin-bottom: 10px;
  color: #666;
}

.project-icon {
  font-size: 1.5em;
  margin-left: 10px;
  color: #000102; /* Cambia el color según tu preferencia */
  transition: color 0.3s;
}

.project-icon:hover {
  color: #0056b3; /* Cambia el color en hover según tu preferencia */
}


.skills-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
}

.skill-item {
  margin: 1rem;
  text-align: center;
  font-size: 2rem;
}

.skill-name {
  margin-top: 0.5rem;
  font-size: 1rem;
}

.certifications-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
}

.certification-item {
  margin: 1rem;
  text-align: center;
  font-size: 2rem;
}

.certification-icon {
  width: 50px;
  height: auto;
  margin-bottom: 0.5rem;
}

.certification-name {
  margin-top: 0.5rem;
  font-size: 1rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.input {
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  font-size: 1rem;
}

.textarea {
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  font-size: 1rem;
  height: 150px;
}

.submit-button {
  padding: 0.75rem;
  background: #6e8efb;
  color: white;
  border: none;
  border-radius: 0.25rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
}

.submit-button:hover {
  background: #5a74d8;
}


.cta-button {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background: white;
  color: #6e8efb;
  border-radius: 0.5rem;
  text-decoration: none;
  font-weight: bold;
  transition: background 0.3s, color 0.3s;
  position: relative;
  overflow: hidden;
}

.cta-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background: rgba(255, 255, 255, 0.3);
  transition: all 0.75s;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 50%;
}

.cta-button:hover::before {
  transform: translate(-50%, -50%) scale(1);
}

.cta-button:hover {
  background: #6e8efb;
  color: white;
}


/* Media Queries para diseño responsivo */
@media (max-width: 768px) {
  .nav {
    flex-direction: column;
  }

  .nav-link {
    margin: 0.5rem 0;
  }

  .skills-list, .certifications-list {
    flex-direction: column;
    align-items: center;
  }

  .project-item {
    width: calc(100% - 2rem);
  }
}

.certifications-list, .skills-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
}

.certification-item, .skill-item {
  margin: 1rem;
  text-align: center;
  font-size: 2rem;
  position: relative;
  transition: transform 0.3s;
  cursor: pointer;
  z-index: 1;
}

.certification-item:hover, .skill-item:hover {
  transform: scale(1.2);
  z-index: 10; /* Asegura que el elemento se superponga a otros */
}

.certification-icon, .skill-icon {
  width: 50px;
  height: auto;
  margin-bottom: 0.5rem;
  transition: width 0.3s, height 0.3s;
}

.certification-item:hover .certification-icon, .skill-item:hover .skill-icon {
  width: 80px;
  height: auto;
}

.certification-description, .skill-description {
  display: none;
  position: absolute;
  top: 110%;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  padding: 0.5rem;
  background: white;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 0.875rem;
  z-index: 10; /* Asegura que la descripción se superponga a otros */
}

.certification-item:hover .certification-description, .skill-item:hover .skill-description {
  display: block;
}


.contact-methods {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.contact-icon {
  color: #333;
  transition: color 0.3s ease;
}

.contact-icon:hover {
  color: #0073b1; /* Color de hover para LinkedIn, puedes cambiarlo según tu preferencia */
}

.contact-icon:active, .contact-icon:focus {
  outline: none;
}



/* ScrollToTopButton.css */
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.scroll-button {
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s, transform 0.3s;
}

.scroll-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.scroll-button:focus {
  outline: none;
}

.scroll-button svg {
  color: white;
  font-size: 20px;
}