body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background: #f7f7f7;
  color: #333;
}

.App-header {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  cursor: pointer;
  border-radius: 0.25rem;
  font-size: 1rem;
  transition: background 0.3s;

  &:hover {
    background-color: #0056b3;
  }
}
